export const GtmVariablesEnum = {
  // Search parameters
  SLICED_POSTAL_CODE: 'postalCodeSliced',
  KEYWORD: 'keyword',
  KEYWORD_NEXT_STEP: 'keywordNextStep',
  FILTER_TYPE: 'filterType',
  FILTER_VALUE: 'filterValue',

  // Service Template
  SERVICE_TEMPLATE_ID: 'stId',
  SERVICE_TEMPLATE_NAME_FR: 'stDescriptionFr',

  // Service Unified
  SERVICE_UNIFIED_ID: 'suId',
  SERVICE_UNIFIED_NAME_FR: 'suDescriptionFr',

  // Establishment Service Template
  ESTABLISHMENT_SERVICE_TEMPLATE_ID: 'estId',
  ESTABLISHMENT_SERVICE_TEMPLATE_NAME_FR: 'estNameFr',
  ESTABLISHMENT_SERVICE_TEMPLATE_PRICING_ENABLED: 'estPricingEnabled',

  // Establishment
  ESTABLISHMENT_ID: 'establishmentId',
  ESTABLISHMENT_TYPE: 'establishmentType',
  ESTABLISHMENT_NAME: 'establishmentName',

  // Place
  PLACE_NAME: 'placeName',
  PLACE_POSTAL_CODE: 'placePostalCode',
  DISTANCE: 'distance',
  PLACE_REGION: 'placeRegion',

  // Pricing
  PRICING: 'pricing'
}
