export default {
  componentName: 'AvailabilityFilter',
  fr: {
    label: 'Disponibilités',
    placeholder: 'Toutes',
    all: 'Toutes les disponibilités',
    showResultsWithT07Avails: '7 prochains jours',
    showResultsWithTa7Avails: 'Dans plus de 7 jours',
    showResultsWithNoAvails: 'Sans disponibilités'
  },
  en: {
    label: 'Availabilities',
    placeholder: 'All',
    all: 'Show all availabilities',
    showResultsWithT07Avails: 'Next 7 days',
    showResultsWithTa7Avails: 'In more than 7 next days',
    showResultsWithNoAvails: 'No availability'
  }
}
