import includes from 'lodash/includes'

let authWindow
let windowOpts = 'toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,'
let callback

// To do : clean up after jwt deprecated
export const signInWindow = async (url) => {
  window.removeEventListener('message', callback)
  const windowArea = {
    width: window.outerWidth < 440 ? window.outerWidth : 440,
    height: Math.floor(window.outerHeight * 0.8)
  }

  if (windowArea.width < 360) {
    windowArea.width = 360
  }
  if (windowArea.height < 630) {
    windowArea.height = 630
  }
  windowArea.left = Math.floor(window.screenX + (window.outerWidth - windowArea.width) / 2)
  windowArea.top = Math.floor(window.screenY + (window.outerHeight - windowArea.height) / 8)

  windowOpts += `width=${windowArea.width},`
  windowOpts += `height=${windowArea.height},`
  windowOpts += `left=${windowArea.left},`
  windowOpts += `top=${windowArea.top},`

  authWindow = window.open(url, 'profileLogin', windowOpts)

  return new Promise((resolve, reject) => {
    callback = receiveMessage(resolve, reject)
    window.addEventListener('message', callback, false)
  })
}

// To do : clean up after jwt deprecated
const receiveMessage = (resolve, reject) => (e) => {
  if (
    !includes(e.origin, 'profile.clicsante.ca') &&
    !includes(e.origin, 'profile.pharmaservices.ca') &&
    !includes(e.origin, 'profile.staging4.clicsante.ca') &&
    !includes(e.origin, 'profile.staging4.pharmaservices.ca') &&
    !includes(e.origin, 'profile.pentest.clicsante.ca') &&
    !includes(e.origin, 'profile.pentest.pharmaservices.ca') &&
    !includes(e.origin, 'profile.dev.clicsante.ca') &&
    !includes(e.origin, 'profile.dev.pharmaservices.ca') &&
    !includes(e.origin, 'localhost:8084')
  ) {
    authWindow.close()
    return reject(new Error('Origin not allowed'))
  }

  authWindow.close()
  return resolve()
}
