export default {
  componentName: 'EstablishmentTypeFilter',
  fr: {
    label: 'Établissements',
    all: 'Tous',
    placeholder: 'Tous',
    withPharmacy: 'Pharmacie',
    withPrivateClinic: 'Clinique',
    withHealthEstablishment: 'Public',
    others: 'autres'
  },
  en: {
    label: 'Establishment',
    all: 'Show all',
    placeholder: 'All',
    withPharmacy: 'Pharmacy',
    withPrivateClinic: 'Clinic',
    withHealthEstablishment: 'Public',
    others: 'others'
  }
}
