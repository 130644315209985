export const ThemeComponentColors = {
  'cs-white': '#ffffff',
  'cs-black': '#000000',
  'cs-grey': '#f1f1f1',
  'cs-grey-dark': '#d5d5d5',
  'cs-dark-gray': '#1d2939',
  'cs-primary': '#5eceff',
  'cs-secondary': '#00bdff',
  'cs-tertiary': '#107aca',
  'cs-fourtiary': '#00577d',
  'cs-quinary': '#fcc987',
  'cs-bg-primary': '#fcfcfd',
  'cs-bg-secondary': '#f2f7fd',
  'cs-bg-tertiary': '#e9f3ff',
  'cs-dark-primary': '#4b4b4b',
  'cs-dark-tertiary': '#464646',
  'cs-dark-fourtiary': '#101828',
  'cs-cookie-compliance': '#237afc'
}
