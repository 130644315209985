export const ThemeVariables = {
  'search-input-font-size': '1rem',
  'search-input-height': '3.25rem',
  'search-input-placeholder-opacity': '1',
  'search-input-field-height': '1.75rem',
  'search-input-field-padding': '0.75rem',
  'search-input-opacity': '1',
  'search-input-icon-size': '1rem',
  'search-input-icon-right-size': '1.5rem',
  'search-input-icon-margin': '0.25rem',
  'stats-table-total-radius': '8px'
}
