export default {
  componentName: 'SearchFilters',
  fr: {
    withWithoutFees: 'Avec et sans frais',
    withoutFees: 'Sans frais',
    label: 'Frais',
    placeholder: 'Toutes'
  },
  en: {
    withWithoutFees: 'Fees and no fees',
    withoutFees: 'No fees',
    label: 'Fees',
    placeholder: 'All'
  }
}
